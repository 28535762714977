const Logo = ({ stroke, width, fill }) => (
    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.57 218.99">
        <defs>
            <style>
                {`
                    .cls-1 {
                        fill: ${fill || 'none'};
                        stroke: ${stroke || '#000'};
                        stroke-miterlimit: 10;
                        stroke-width: ${width || '18px'};
                    }
                `}
            </style>
        </defs>
        <path className="cls-1" d="M100.29,121.5h-42.2c-25.5-23.8-21.35-63.56-21.35-63.56,50.05.03,61.25,39.83,63.56,63.56Z" />
        <path className="cls-1" d="M333.53,120.5h-44.4c.43-3.6,1.06-6.59,1.99-10.66t0-.03c1.08-4.74,2.57-9.68,4.61-14.54t.01-.03c7.89-18.85,24.01-36.48,56.7-36.49,0,0,3.86,37.68-18.91,61.75Z" />
        <line className="cls-1" x1="103.59" y1="121.42" x2="100.29" y2="121.42" />
        <path className="cls-1" d="M106.46,122.23H15.34c0,55.34,69.14,83.01,69.14,83.01h110.95" />
        <path className="cls-1" d="M285.83,121.42h90.4c0,55.34-69.85,83.83-69.85,83.83h-110.95" />
        <polyline className="cls-1" points="287.7 121.42 284.9 121.42 284.4 121.42" />
        <g>
            <path className="cls-1" d="M195.01,203.24c3.2-1.17,7.48-2.88,12.5-5.24" />
            <path className="cls-1" d="M207.51,198c-5.02,2.36-9.3,4.07-12.5,5.24-3.2-1.17-7.48-2.88-12.5-5.24-31.86-15.01-93.33-56.53-92.99-153.28.05-14.44,9.74-26.59,22.39-28.25,23.2-3.05,60.1,1.7,83.1,53.09,22.99-51.39,59.9-56.14,83.1-53.09,12.65,1.67,22.34,13.81,22.39,28.25.35,96.75-61.13,138.27-92.99,153.28Z" />
        </g>
    </svg>
);

export default Logo;